import React from 'react';
import {Button, Form, FormGroup, Input, Label, FormText} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';

import {withFormUtils} from '../../hoc/with-form-utils';
import {MailingState} from '../../enum/MailingState';
import {MailingStateText} from '../../enum/MailingStateText';
import {PropTypes} from 'prop-types';

class MailingForm extends React.Component {
	static propTypes = {
		interestTypes: PropTypes.array,
	};

	static defaultProps = {
		interestTypes: [],
	};

	componentDidMount() {
		this.props.setDefaultValue({
			plannedSend: new Date().toISOString(),
		});
		this.props.setDefaultValue('interests', []);
		this.props.setDefaultValue('externalId', []);
		this.props.addTrimmedFields('externalId', []);
		if(this.props.newMailing) {
			this.props.onInputChange({ target: { name: 'state', value: { label: 'Concept', value: 'draft'} }});
		}
	}

	getCurrentInterestTypes = () => {
		const typesData = this.props.getdata('interests');

		const isArrayOfStrings = Array.isArray(typesData) && typesData.every(t => typeof t === 'string');

		if (isArrayOfStrings) {
			return typesData.map(t => ({ label: t, value: t }));
		}

		return typesData;
	};

	render() {
		const {getdata, onInputChange, onNumberInputChange, onFormSubmit} = this.props;

		const plannedSend = getdata('plannedSend');
		const momentDate = plannedSend ? moment(getdata('plannedSend')) : moment();

		const mailingStates = Object.keys(MailingState).map(state => {
			return {
				value: MailingState[state],
				label: MailingStateText[state],
			};
		});

		const interestTypes = (this.props.interestTypes || []).map(type => {
			return {
				value: type,
				label: type,
			};
		});

		const stateValueRaw = getdata('state');
		const stateValue = typeof stateValueRaw === 'string' ? mailingStates.find(s => s.value === stateValueRaw) : stateValueRaw;
		const tripolisIds = getdata('externalId') || [];
		if(tripolisIds.every(v => v)) {
			tripolisIds.push('');
		}

		return <Form onSubmit={onFormSubmit}>
			<FormGroup>
				<Label>Naam</Label>
				<Input type="text" name="name" value={getdata('name')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Geplande verzenddatum. Let op: dit heeft rechtstreeks gevolgen voor het MPR.</Label>
				<DatePicker
					className="form-control"
					selected={momentDate.isValid() ? momentDate.toDate() : new Date()}
					onChange={date => {
						onInputChange({
							target: {
								name: 'plannedSend',
								value: date.toISOString(),
							},
						});
					}}
					dropdownMode="scroll"
					dateFormat="dd-MM-yyyy"
				/>
			</FormGroup>

			<FormGroup>
				<Label>Bruto adresprijs</Label>
				<Input type="text" name="budget" value={getdata('budget')} onChange={onNumberInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Aantal adressen 100%</Label>
				<Input type="number" name="addresses" value={getdata('addresses')} onChange={onInputChange} />
			</FormGroup>

			<FormGroup>
				<Label>Interesses</Label>
				<Select
					value={this.getCurrentInterestTypes()}
					options={interestTypes}
					onChange={selected => onInputChange({ target: { name: 'interests', value: selected || [] }})}
					isMulti
					isSearchable
				/>
			</FormGroup>

			<FormGroup>
				<Label>Geef de status van de verzending aan.</Label>
				<Select
					value={stateValue}
					options={mailingStates}
					onChange={selected => onInputChange({ target: { name: 'state', value: selected }})}
				/>
				<FormText>
					<strong>Concept:</strong> Voor tellingen en heeft geen gevolgen voor de maildruk.<br />
					<strong>Definitief:</strong> Ingekocht en zorgt ervoor dat de adressen worden gereserveerd. Let op dat de ingeplande verzenddatum goed staat ivm. de maildruk.<br />
					<strong>Aangeboden:</strong> Verzonden en zorgt ervoor dat de verzending getoond wordt in het MPR. Let op dat de verzenddatum daadwerkelijk overeenkomt van Tripolis.<br />
					<strong>Geannuleerd:</strong> Deze haalt de verzending uit het MPR en de adressen komen vrij uit de maildruk.
				</FormText>
			</FormGroup>

			<FormGroup>
				<Label>Vul je Tripolis Mailing ID in</Label>
				{tripolisIds.map((id, i) => <Input key={i} type="text" name="externalId" value={id} onChange={e => {
					const value = tripolisIds.slice();
					value[i] = e.target.value;
					onInputChange({ target: { name: 'externalId', value }});
				}} />)}
				<FormText>
				Voorbeeld Mailing ID: <strong>7y79orw7EPMvWO1pUi0iug</strong><br />

				Die kan je halen uit je jobSummaryReport:<br />https://td38.tripolis.com/dialogue/reports/publishing/emails/jobSummaryReport.html?id=<strong>7y79orw7EPMvWO1pUi0iug</strong>&amp;list=all
				</FormText>
			</FormGroup>

			<FormGroup>
				<Button type="submit" color="primary">Submit</Button>
			</FormGroup>
		</Form>;
	}
}

export default withFormUtils(MailingForm);
