import React from 'react';
import PropTypes from 'prop-types';

export const withFormUtils = BaseComponent => {
	return class ComponentWithFormUtils extends React.Component {
		static propTypes = {
			data: PropTypes.object,
			onSubmit: PropTypes.func.isRequired,
		};

		static defaultProps = {
			data: {},
		};

		constructor(props) {
			super(props);

			this.state = {
				formData: {},
				defaultValues: {},
				trimmedFields: [],
			};
		}

		onChange = (e, forceEmpty = false) => {
			const formData = Object.assign({}, this.props.data, this.state.formData);

			const {target} = e;

			if ([ 'checkbox', 'radio' ].includes(target.type)) {
				formData[target.name] = target.checked;
			} else if (target.type === 'number') {
				formData[target.name] = Number(target.value);
			} else if (target.value === '' && !forceEmpty && (!this.props.data || !(target.name in this.props.data))) {
				delete formData[target.name];
			} else {
				formData[target.name] = target.value;
			}

			this.setState({
				formData,
			});
		};

		onNumberChange = e => {
			const {target} = e;

			target.value = target.value.replace(',', '.');

			if (!isNaN(target.value) || !target.value) {
				return this.onChange(e, true);
			}
		};

		onSubmit = e => {
			e.preventDefault();

			const data = Object.assign({}, this.state.defaultValues, this.props.data, this.state.formData);

			this.props.onSubmit(this.trimFields(data));

			return false;
		}

		getData = prop => {
			if (prop in this.state.formData) {
				return this.state.formData[prop];
			} else if (prop in this.props.data) {
				return this.props.data[prop];
			}

			return '';
		}

		setDefaultValue = (keyOrObject, value) => {
			this.setState(prevState => {
				const newPartialDefaults = typeof keyOrObject === 'string' ? {
					[keyOrObject]: value,
				} : keyOrObject;

				return {
					defaultValues: Object.assign({}, prevState.defaultValues, newPartialDefaults),
				};
			});
		}

		addTrimmedFields = (...fields) => {
			this.setState(prevState => {
				return {
					trimmedFields: prevState.trimmedFields.concat(fields),
				};
			});
		}

		trimFields = data => {
			return Object.keys(data).reduce((trimmedData, key) => {
				return {
					...trimmedData,
					[key]: this.state.trimmedFields.includes(key) ? Array.isArray(data[key]) ? data[key].map(v => v.trim()).filter(v => v) : data[key].trim() : data[key],
				};
			}, {});
		}

		render() {
			return <BaseComponent
				getdata={this.getData}
				onFormSubmit={this.onSubmit}
				onInputChange={this.onChange}
				onNumberInputChange={this.onNumberChange}
				setDefaultValue={this.setDefaultValue}
				addTrimmedFields={this.addTrimmedFields}
				formData={this.state.formData}
				{...this.props}
			/>;
		}
	};
};
