import React from 'react';
import CountsComponent from '../components/campaigns/CountsComponent';
import * as api from '../api';

export default class CountsContainer extends React.Component {
	state = {
		countData: null,
		useBlacklist: true,
		partners: [],
		partner: ''
	};

	fetchCounts(useBlacklist = this.state.useBlacklist) {
		api.getCounts(useBlacklist)
			.then(res => {
				const countData = res.result.sort((a, b) => a.dataset.sort - b.dataset.sort);
				const partners = [...countData.reduce((s, row) => {
					s.add(row.dataset.partner);
					return s;
				}, new Set())].sort();
				this.setState({ countData, useBlacklist, partners });
			})
			.catch(err => {
				console.log(err);
			});
	}

	componentWillMount() {
		this.fetchCounts();
	}

	toggleBlacklist = useBlacklist => {
		this.fetchCounts(useBlacklist);
	};

	render() {
		const { countData, useBlacklist, partners, partner } = this.state;
		let data = countData;
		if(partner !== '') {
			data = data.filter(row => row.dataset.partner === partner);
		}
		return <CountsComponent
			countData={data}
			useBlacklist={useBlacklist}
			partners={partners}
			partner={partner}
			setPartner={e => this.setState({ partner: e.target.value })}
			toggleBlacklist={this.toggleBlacklist} />;
	}
}
